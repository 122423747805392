import { graphql } from "gatsby";
import React, { useState, Fragment } from "react";
import { Container } from "react-bootstrap";
import HeaderOne from "@/components/header-one";
import Layout from "@/components/layout";
import MenuContextProvider from "@/context/menu-context";
import Footer from "@/components/footer";
import PortableText from "@/components/portableText";
import Seo from "@/components/seo";
import moment from "moment";
import imageUrlBuilder from "@sanity/image-url";
import clientConfig from "../../client-config";

export const query = graphql`
  query EventTemplateQuery($id: String!) {
    event: sanityEvent(id: { eq: $id }) {
      id
      _rawDescription
      address
      registrationReq
      paidEvent
      organizer
      organizedBy
      outsideReg
      allDay
      registrationLink
      title
      zoomLink
      eventType
      eventStart {
        utc
        local
        timezone
      }
      eventEnd {
        utc
        timezone
        local
      }
      children {
        id
      }
      speakers {
        slug {
          current
        }
        title
        name
        image {
          hotspot {
            height
            width
            x
            y
          }
          crop {
            bottom
            left
            right
            top
          }
          asset {
            url
          }
        }
      }
      paidEvent
      price {
        price
        id
        slug {
          current
        }
        title
        description
      }
      slug {
        current
      }
    }
  }
`;

const FerraraEvent = (props, { location }) => {
  const { data } = props;
  const event = data && data.event;

  const [inputFields, setInputFields] = useState([
    {
      attendee: "",
      attendeeEmail: "",
      lastName: "",
      registrantName: "",
      registrantOrg: "",
      registrantTitle: "",
      registrantEmail: "",
    },
  ]);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handlePaidSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        alert("Please checkout to complete registration.");
        setFormSubmitted(true);
      })
      .catch((error) => alert(error));
  };
  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ attendee: "", attendeeEmail: "", lastName: "" });
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "attendee") {
      values[index].attendee = event.target.value;
    } else {
      values[index].lastName = event.target.value;
    }
    if (event.target.name === "attendeeEmail") {
      values[index].attendeeEmail = event.target.value;
    }

    setInputFields(values);
  };

  const builder = imageUrlBuilder(clientConfig.sanity);
  function urlFor(source) {
    return builder.image(source);
  }

  const pageUrl = typeof window !== "undefined" ? window.location.href : "";

  return (
    <MenuContextProvider>
      {event && (
        <Layout PageTitle={event.title}>
          <Seo
            title={event.title}
            description="Ferrara Fiorenza PC offers a blend of expertise and thoughtful representation in analyzing and solving the challenges faced by school districts, BOCES and all types of employers."
            image={
              event.mainImage
                ? event.mainImage.asset.url
                : "../../breadcrumb.jpg"
            }
            location={event.slug.current}
          />
          <HeaderOne />
          <section
            style={{
              backgroundImage: `url(${
                event.mainImage
                  ? event.mainImage.asset.url
                  : "../../breadcrumb.jpg"
              })`,
            }}
            className="breadcrumb_section text-center"
          >
            <div>
              <h2 className="text-white">{event.title}</h2>
            </div>
          </section>
          <section className="mb-6 bg-gray-200">
            <div className="py-2 container">
              <div className="md:flex gap-4 mb-3">
                {event.eventType && (
                  <p className="text-lg">
                    <strong className="text-sm detail_title">Type</strong>
                    <br /> {event.eventType} Event
                  </p>
                )}
                {event.address && (
                  <p className="text-lg">
                    <strong className="text-sm detail_title">Location</strong>
                    <br /> {event.address}
                  </p>
                )}
                {event.zoomLink && (
                  <p className="text-lg">
                    <strong className="text-sm detail_title">Livestream</strong>
                    <br /> <a href={event.zoomLink}>Viewer Link</a>
                  </p>
                )}
                {event.eventStart && (
                  <p className="text-lg">
                    <strong className="text-sm detail_title">Start</strong>
                    <br /> {moment(event.eventStart.utc).format(
                      "MMM D, YYYY"
                    )}{" "}
                    at {moment(event.eventStart.utc).format("h:mma")}
                  </p>
                )}
                {event.eventEnd && (
                  <p className="text-lg">
                    <strong className="text-sm detail_title">End</strong>
                    <br /> {moment(event.eventEnd.utc).format(
                      "MMM D, YYYY"
                    )} at {moment(event.eventEnd.utc).format("h:mma")}
                  </p>
                )}
                {event.categories && (
                  <p className="text-lg">
                    <strong className="text-sm detail_title">Category</strong>
                    <br />{" "}
                    {event.categories.map((event, index) => (
                      <span key={index} href="#">
                        {event.title}{" "}
                      </span>
                    ))}
                  </p>
                )}
                {event.organizedBy === "Ferrara" ? (
                  <p className="text-lg">
                    <strong className="text-sm detail_title">Organizer:</strong>
                    <br /> Ferrara Fiorenza PC
                  </p>
                ) : (
                  event.organizer && (
                    <p className="text-lg">
                      <strong className="text-sm detail_title">
                        Organizer:
                      </strong>
                      <br /> {event.organizer}
                    </p>
                  )
                )}
              </div>
            </div>
          </section>
          <section className="my-8">
            <Container>
              {event._rawDescription !== null && (
                <div className="mb-6 md:w-50">
                  <h3 className="mb-3 font-bold">Description</h3>
                  <PortableText blocks={event._rawDescription} />
                </div>
              )}
              {event.speakers.length > 0 && (
                <div className="mb-6">
                  <h3 className="mb-3 font-bold">
                    {event.speakers.length >= 2 ? "Speakers" : "Speaker"}
                  </h3>
                  <div className="flex md:flex-col sm:flex-col flex-row gap-4 flex-wrap">
                    {event.speakers.map((speaker, index) => (
                      <div
                        key={index}
                        className="flex w-fit drop-shadow-sm items-center mb-6 flex-initial rounded-full bg-gray-300 pr-6 h-24 line-height-username2"
                      >
                        <img
                          src={urlFor(speaker.image)
                            .width(300)
                            .height(300)
                            .url()}
                          alt={speaker.title}
                          className="rounded-full float-left h-full"
                        />
                        <div className="flex flex-col ml-3">
                          <a href={"/attorneys/" + speaker.slug.current}>
                            <span className="text-lg">{speaker.name}</span>{" "}
                          </a>
                          <span className="text-md text-gray-500">
                            {speaker.title}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {event.outsideReg === "Yes" && (
                <div className="mb-6">
                  <h3 className="mb-3 font-bold">Registration</h3>
                  <p className="text-gray-700 mb-4">
                    This event is hosted by {event.organizer}. Please click the
                    button to register.
                  </p>
                  <a
                    href={event.registrationLink}
                    className="btn-yellow"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Register Now
                  </a>
                </div>
              )}

              {event.organizedBy === "Ferrara" &&
                event.registrationReq === "Yes" &&
                event.paidEvent !== "Paid" && (
                  <div className="mb-6">
                    <h3 className="mb-3 font-bold">Registration</h3>
                    <div className="bg-gray-300 p-4">
                      <p className="text-gray-700 mb-4">
                        Enter the information for you and other attendees from
                        your organization.{" "}
                      </p>
                      <form
                        className="flex flex-col gap-4"
                        netlify-honeypot="bot-field"
                        data-netlify="true"
                        name="Event Registration"
                        method="post"
                      >
                        <input type="hidden" name="bot-field" />
                        <input
                          type="hidden"
                          name="form-name"
                          value="Event Registration"
                          readOnly
                        />
                        <input
                          className="invisible absolute"
                          name="event-name"
                          value={event.title}
                          readOnly
                        />
                        <div>
                          <label htmlFor="registrantName">
                            Your name<span className="text-red-500">*</span>
                          </label>
                          <input
                            className="p-2 input_m_right"
                            type="text"
                            name="name"
                            placeholder="Your name"
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="registrantOrg">
                            Your School/Business{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            className="p-2"
                            type="text"
                            name="School/business"
                            placeholder="Organization name"
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="registrantTitle">
                            Your Title<span className="text-red-500">*</span>
                          </label>
                          <input
                            className="p-2"
                            type="text"
                            name="Title"
                            placeholder="Job title"
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="registrantEmail">
                            Your Email<span className="text-red-500">*</span>
                          </label>
                          <input
                            className="p-2"
                            type="EMAIL"
                            name="email"
                            placeholder="you@example.com"
                            required
                          />
                        </div>

                        <div className="form-row">
                          {inputFields.map((inputField, index) => (
                            <Fragment key={`${inputField}~${index}`}>
                              <div className="">
                                <label htmlFor="attendee">
                                  Additional Attendee #{index + 1} Name
                                </label>
                                <input
                                  type="text"
                                  className="p-2"
                                  id="attendee"
                                  name="attendee"
                                  placeholder="Attendee name"
                                  value={inputField.attendee}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                />
                              </div>
                              <div className="">
                                <label htmlFor="attendeeEmail">
                                  Additional Attendee #{index + 1} Email
                                </label>
                                <input
                                  type="text"
                                  className="p-2"
                                  id="attendeeEmail"
                                  name="attendeeEmail"
                                  placeholder="Attendee email"
                                  value={inputField.attendeeEmail}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                />
                              </div>
                              <div className="form-group">
                                <button
                                  style={{ fontSize: "1.25rem" }}
                                  className="btn text-ferraraBlue font-bold"
                                  type="button"
                                  disabled={index === 0}
                                  onClick={() => handleRemoveFields(index)}
                                >
                                  -
                                </button>
                                <button
                                  style={{ fontSize: "1.25rem" }}
                                  className="btn text-ferraraBlue font-bold"
                                  type="button"
                                  onClick={() => handleAddFields()}
                                >
                                  +
                                </button>
                                <span className="text-xs text-gray-500">
                                  Click to remove or add attendees
                                </span>
                              </div>
                            </Fragment>
                          ))}
                          <span className="text-red-500 text-xs">
                            * indcates required field
                          </span>
                        </div>

                        <button className="btn-yellow" value="Register">
                          SUBMIT
                        </button>
                      </form>
                    </div>
                  </div>
                )}

              {/* Paid Event */}
              {event.organizedBy === "Ferrara" &&
                event.registrationReq === "Yes" &&
                event.price !== null &&
                event.price.length >= 1 && (
                  <div className="mb-6">
                    <h3 className="mb-3 font-bold">Registration</h3>
                    {formSubmitted ? (
                      <div className="py-4 bg-gray-200">
                        <h4 className="px-4">Step 2</h4>
                        <p className="text-gray-700 px-4 mt-3 mb-2 text-lg">
                          Pay for the number of tickets you need for the event.
                        </p>
                        <p className="text-gray-700 px-4 mb-2 text-sm">
                          If you would like to pay by credit card, please add to
                          cart and go to checkout. <br></br>If you would like to
                          pay by PO, please email the PO to
                          kasenn@ferrarafirm.com.
                        </p>
                        <div>
                          {event.price.map((event, index) => (
                            <section className="p-4 bg-gray-200">
                              <h5>{event.title}</h5>
                              <p className="text-gray-600 text-lg font-bold">
                                ${event.price}
                              </p>
                              {/* <p className="text-gray-500">
                                {event.description}
                              </p> */}
                              <div className="flex gap-2 mt-2">
                                <button
                                  className="snipcart-add-item bg-ferraraTeal hover:opacity-70 text-white font-bold py-2 px-4 rounded"
                                  data-item-id={event.id}
                                  data-item-price={event.price}
                                  data-item-url={pageUrl}
                                  data-item-name={event.title}
                                  data-item-quantity="1"
                                  value="Register"
                                  key={index}
                                >
                                  Add to cart
                                </button>
                              </div>
                            </section>
                          ))}
                          <button
                            className="snipcart-checkout ml-6 bg-ferraraBlue hover:opacity-70 text-white font-bold py-2 px-4 rounded"
                            value="Register"
                          >
                            Checkout
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="bg-gray-300 p-4">
                        <h4>Step 1</h4>
                        <p className="text-gray-700 mb-4">
                          Enter the information for you and other attendees from
                          your organization.{" "}
                        </p>
                        <form
                          className="flex flex-col gap-4"
                          netlify-honeypot="bot-field"
                          data-netlify="true"
                          name="Event Registration"
                          onSubmit={handlePaidSubmit}
                        >
                          <input type="hidden" name="bot-field" />
                          <input
                            type="hidden"
                            name="form-name"
                            value="Event Registration"
                            readOnly
                          />
                          <input
                            className="invisible absolute"
                            name="event-name"
                            value={event.title}
                            readOnly
                          />
                          <div>
                            <label htmlFor="registrantName">
                              Your name<span className="text-red-500">*</span>
                            </label>
                            <input
                              className="p-2 input_m_right"
                              type="text"
                              name="name"
                              placeholder="Your name"
                              required
                            />
                          </div>
                          <div>
                            <label htmlFor="registrantOrg">
                              Your School/Business
                              <span className="text-red-500">*</span>
                            </label>
                            <input
                              className="p-2"
                              type="text"
                              name="School/business"
                              placeholder="Organization name"
                              required
                            />
                          </div>
                          <div>
                            <label htmlFor="registrantTitle">
                              Your Title<span className="text-red-500">*</span>
                            </label>
                            <input
                              className="p-2"
                              type="text"
                              name="Title"
                              placeholder="Job title"
                              required
                            />
                          </div>
                          <div>
                            <label htmlFor="registrantEmail">
                              Your Email<span className="text-red-500">*</span>
                            </label>
                            <input
                              className="p-2"
                              type="EMAIL"
                              name="email"
                              placeholder="you@example.com"
                              required
                            />
                          </div>

                          <div className="form-row">
                            {inputFields.map((inputField, index) => (
                              <Fragment key={`${inputField}~${index}`}>
                                <div className="">
                                  <label htmlFor="attendee">
                                    Additional Attendee #{index + 1} Name
                                  </label>
                                  <input
                                    type="text"
                                    className="p-2"
                                    id="attendee"
                                    name="attendee"
                                    placeholder="Attendee name"
                                    value={inputField.attendee}
                                    onChange={(event) =>
                                      handleInputChange(index, event)
                                    }
                                  />
                                </div>
                                <div className="">
                                  <label htmlFor="attendeeEmail">
                                    Additional Attendee #{index + 1} Email
                                  </label>
                                  <input
                                    type="text"
                                    className="p-2"
                                    id="attendeeEmail"
                                    name="attendeeEmail"
                                    placeholder="Attendee email"
                                    value={inputField.attendeeEmail}
                                    onChange={(event) =>
                                      handleInputChange(index, event)
                                    }
                                  />
                                </div>
                                <div className="form-group">
                                  <button
                                    style={{ fontSize: "1.25rem" }}
                                    className="btn text-ferraraBlue font-bold"
                                    type="button"
                                    disabled={index === 0}
                                    onClick={() => handleRemoveFields(index)}
                                  >
                                    -
                                  </button>
                                  <button
                                    style={{ fontSize: "1.25rem" }}
                                    className="btn text-ferraraBlue font-bold"
                                    type="button"
                                    onClick={() => handleAddFields()}
                                  >
                                    +
                                  </button>
                                  <span className="text-xs text-gray-500">
                                    Click to remove or add attendees
                                  </span>
                                </div>
                              </Fragment>
                            ))}
                            <span className="text-red-500 text-xs">
                              * indcates required field
                            </span>
                          </div>

                          <button
                            className=" bg-ferraraTeal hover:opacity-70 text-white font-bold py-2 px-4 rounded"
                            value="Register"
                          >
                            Next Step
                          </button>
                        </form>
                        {event.price.map((event, index) => (
                          <section className="hidden">
                            <button
                              className="snipcart-add-item "
                              data-item-id={event.id}
                              data-item-price={event.price}
                              data-item-url={pageUrl}
                              data-item-name={event.title}
                              data-item-quantity="1"
                              value="Register"
                              key={index}
                            >
                              Add to cart
                            </button>
                          </section>
                        ))}
                      </div>
                    )}
                  </div>
                )}
            </Container>
          </section>
          <Footer />
        </Layout>
      )}
    </MenuContextProvider>
  );
};

export default FerraraEvent;
